import { useMutation } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Form,
  H2,
  H3,
  P,
  Tabs,
  ModalFullScreenInner,
  Breadcrumbs,
  Button,
  Alert,
  InputStepper,
  Label,
  SwitchBlock,
} from "@spillchat/puddles"
import { FunctionComponent, useState } from "react"
import { Helmet } from "react-helmet-async"
import { Link } from "react-router-dom"
import { LightBulbIcon } from "@heroicons/react/24/outline"

import {
  InviteCustomCreatePackageMutation,
  InviteCustomCreatePackageMutationVariables,
  InviteCustomUpdateCoPayMutation,
  InviteCustomUpdateCoPayMutationVariables,
  PackageState,
  PackageType,
  SpillSubscriptionPlan,
} from "types/graphql"
import { useGoBack } from "common/hooks/useGoBack"
import { mutations } from "features/invite-custom/invite-custom.mutations"
import { useUser } from "common/context/userContext"
import { FEATURE_FLAGS } from "common/constants/flags"

import { formSchema } from "./admin-therapy-specialised-support-create-adhd.schema"

import type { InviteCustomForm } from "./admin-therapy-specialised-support-create-adhd.schema"

export const AdminTherapySpecialisedSupportCreateAdhd: FunctionComponent =
  () => {
    const user = useUser()
    const { subscriptionPlan } = user.company ?? {}

    const goBack = useGoBack({ isAdmin: true })
    const [step, setStep] = useState(0)

    const form = Form.useForm<InviteCustomForm>({
      reValidateMode: "onChange",
      resolver: zodResolver(formSchema),
      defaultValues: {
        numberSessions: 0,
        forAllEmployees: true,
        numberMonthsToUse: null,
        adminReason: "",
        packageType: PackageType.ADHD,
        lifeEventId: "10c92c58-0d9f-4173-9f88-de1dec623e70",
        iconUrl:
          "https://spill-public-assets.s3.eu-west-2.amazonaws.com/custom-support/thumbnails/therapy-adhd.png",
        userFacingDescription:
          "For employees that experience symptoms of Attention Deficit Hyperactivity Disorder, whether they've had a diagnosis or not.",
        visibleToAllEmployees: true,
        icon: "Light",
        name: "ADHD Support",
        state: PackageState.ACTIVE,
        coPayEnabled: false,
        companyContribution: 300,
      },
    })

    const [createPackage] = useMutation<
      InviteCustomCreatePackageMutation,
      InviteCustomCreatePackageMutationVariables
    >(mutations.createPackage, {
      refetchQueries: ["AdminTherapySpecialisedSupport"],
    })

    const [updateCoPay] = useMutation<
      InviteCustomUpdateCoPayMutation,
      InviteCustomUpdateCoPayMutationVariables
    >(mutations.updateAdhdCoPayAddon)

    const handleSubmit = async (variables: InviteCustomForm) => {
      await createPackage({ variables })
      if (
        variables.coPayEnabled === true &&
        user.flags[FEATURE_FLAGS.ADHD_CO_PAY]
      ) {
        await updateCoPay({
          variables: {
            enabled: true,
            companyContribution: variables?.companyContribution,
          },
        })
      }
      setStep(2)
    }

    const handleTerms = async () => {
      if (!user.flags[FEATURE_FLAGS.ADHD_CO_PAY]) {
        return await handleSubmit(form.getValues())
      }

      setStep(1)
    }

    const tabs = [
      { label: "Introduction", isActive: step >= 0, step: 0 },
      { label: "Add ons", isActive: step >= 1, step: 1 },
      { label: "Confirmation", isActive: step >= 2, step: 2 },
    ]

    return (
      <>
        <Helmet title="Set up ADHD support | Spill" />
        <ModalFullScreenInner
          title="Set up ADHD support"
          onClose={() => goBack()}
        >
          <div className="max-w-xl mx-auto w-full py-6 lg:py-12 px-3 lg:px-0">
            <Tabs.Root
              defaultValue={step.toString()}
              value={step.toString()}
              className="flex flex-col gap-6 lg:gap-12"
            >
              <Form.Root {...form}>
                <Breadcrumbs
                  onStepChange={setStep}
                  currentStep={step}
                  tabs={tabs}
                ></Breadcrumbs>
                <form onSubmit={form.handleSubmit(handleSubmit)}>
                  <Tabs.Content value="0">
                    <div className="flex flex-col gap-12">
                      <div className="flex flex-col gap-8">
                        <div className="flex flex-col gap-2">
                          <H2>Before you start</H2>
                          <P muted>
                            Some things to note before you configure this
                            support
                          </P>
                        </div>
                        {subscriptionPlan === SpillSubscriptionPlan.TEAM && (
                          <div className="flex flex-col gap-2">
                            <H3>You won't be charged extra</H3>
                            <P muted>
                              There is no fee for enabling this support. Nice!
                            </P>
                          </div>
                        )}
                        <div className="flex flex-col gap-2">
                          <H3>
                            ADHD sessions are separate to regular sessions
                          </H3>
                          <P muted>
                            Any sessions you add to this package will be
                            separate to your employees' regular session
                            allowance. Employees will still be able to book
                            regular therapy sessions even if they are also
                            accessing a specialist support package.
                          </P>
                        </div>
                        {subscriptionPlan ===
                          SpillSubscriptionPlan.ESSENTIAL && (
                          <div className="flex flex-col gap-2">
                            <H3>How it's paid for</H3>
                            <P muted>
                              You're on the Essentials plan, which means you'll
                              pay for each specialist session any employees use.
                              These will come out of your overall company
                              budget, just like regular session credits.
                            </P>
                          </div>
                        )}
                      </div>
                      <Button
                        onClick={async () => await handleTerms()}
                        variant="primary"
                      >
                        I understand
                      </Button>
                    </div>
                  </Tabs.Content>
                  <Tabs.Content value="1">
                    <div className="flex flex-col gap-8">
                      <div className="flex flex-col gap-4">
                        <H2>Add ons</H2>
                        <P muted>Choose the add ons you'd like in the policy</P>
                      </div>
                      <div className="flex flex-col gap-4">
                        <Form.Field
                          control={form.control}
                          name="visibleToAllEmployees"
                          render={() => (
                            <Form.Item>
                              <Form.Control>
                                <SwitchBlock
                                  icon={LightBulbIcon}
                                  defaultChecked={form.getValues(
                                    "coPayEnabled"
                                  )}
                                  onCheckedChange={value => {
                                    form.setValue("coPayEnabled", value)
                                  }}
                                  label="Assessment Co-Pay"
                                  description="Pay in part for an ADHD assessment"
                                />
                              </Form.Control>
                              <Form.Message />
                            </Form.Item>
                          )}
                        />
                      </div>
                      {form.watch("coPayEnabled") === true && (
                        <div className="flex flex-col gap-2">
                          <H3>How much do you want to pay?</H3>
                          <div className="flex flex-col gap-2">
                            <Label htmlFor="companyContribution">
                              Contribution per person
                            </Label>
                            <Form.Field
                              control={form.control}
                              name="companyContribution"
                              render={({ field }) => (
                                <Form.Item className="flex flex-col">
                                  <Form.Control>
                                    <InputStepper
                                      step="50"
                                      max={600}
                                      min={0}
                                      value={field.value.toString()}
                                      onStepperChange={value => {
                                        form.setValue(
                                          "companyContribution",
                                          parseInt(value)
                                        )
                                      }}
                                      {...form.register("companyContribution", {
                                        setValueAs: (value: string) => {
                                          if (isNaN(parseInt(value))) {
                                            return ""
                                          } else {
                                            return parseInt(value)
                                          }
                                        },
                                      })}
                                    />
                                  </Form.Control>
                                  <Form.Message className="!mt-0" />
                                  <P size="xs" muted>
                                    An assessment on Spill is £600
                                  </P>
                                </Form.Item>
                              )}
                            />
                          </div>
                        </div>
                      )}
                      <Alert title="Why add this?" variant="info">
                        <P size="xs">
                          An ADHD assessment may give people a better
                          understanding of symptoms they experience, as well as
                          access to further treatment or medication.
                        </P>
                        <P size="xs">
                          Offering this on Spill will be faster and cheaper than
                          going via the NHS.
                        </P>
                      </Alert>
                      <Button type="submit" variant="primary">
                        Confirm
                      </Button>
                    </div>
                  </Tabs.Content>
                  <Tabs.Content value="2">
                    <div className="flex flex-col gap-8">
                      <div className="flex flex-col gap-4">
                        <H2>ADHD Support has been set up</H2>
                        <P muted>
                          We've added ADHD Support for your team. You'll now be
                          able to edit the settings for this support, as well as
                          add individual people.
                        </P>
                      </div>
                      <Button variant="primary" asChild>
                        <Link to="/admin/therapy/specialised-support">
                          Finish
                        </Link>
                      </Button>
                    </div>
                  </Tabs.Content>
                </form>
              </Form.Root>
            </Tabs.Root>
          </div>
        </ModalFullScreenInner>
      </>
    )
  }
