import {
  Button,
  Checkbox,
  Form,
  H2,
  H3,
  Input,
  Label,
  P,
  Select,
  Separator,
} from "@spillchat/puddles"
import { FunctionComponent, useEffect, useMemo } from "react"
import { getCountryCallingCode } from "libphonenumber-js"

import { phoneCountryCodes } from "common/helpers/phone-country-codes"
import { useAnalytics } from "common/context/analyticsContext"

import { useBookingFormValidation } from "../hooks/useBookingFormValidation"
import { TherapyBookingFormWrapper } from "../components/therapy-booking-form-wrapper"
import { TherapyBookingSummaryCardProps } from "../components/therapy-booking-summary-card"
import { TherapyBookingViewEditField } from "../components/therapy-booking-viewEditField"
import { TherapyBookingFormSchema } from "../hooks/useTherapyBookingForm"

interface TherapyBookingClinicalTabProps {
  onNext: () => void
  onEditBooking: () => void
  summaryCardProps: TherapyBookingSummaryCardProps
}

export const TherapyBookingClinicalTab: FunctionComponent<
  TherapyBookingClinicalTabProps
> = (props: TherapyBookingClinicalTabProps) => {
  const { validationFormSectionAndContinue, checkValid, registerFieldRef } =
    useBookingFormValidation({
      formSection: "clinicalInfo",
      onNext: props.onNext,
    })

  const { track } = useAnalytics()
  useEffect(() => {
    track("Booking tab changed to Clinical Information")
  }, [])

  const form = Form.useFormContext<TherapyBookingFormSchema>()

  const formattedEmergencyContact = useMemo(() => {
    const countryCode = form.watch("clinicalInfo.emergencyCountryCode")
    const phoneNumber = form.watch("clinicalInfo.emergencyContactPhone")
    const name = form.watch("clinicalInfo.emergencyContactName")
    if (
      countryCode !== undefined &&
      phoneNumber !== undefined &&
      name !== undefined &&
      name !== "" &&
      phoneNumber !== ""
    ) {
      const extCode = getCountryCallingCode(countryCode)
      return (
        <>
          <P muted>{name}</P>
          <P muted>{`+(${extCode ?? ""}) ${phoneNumber}`}</P>
        </>
      )
    }

    return undefined
  }, [
    form.watch("clinicalInfo.emergencyCountryCode"),
    form.watch("clinicalInfo.emergencyContactPhone"),
  ])

  const handleBlur = async (field: string) => {
    await checkValid()
    logChange(field)
  }

  const logChange = (field: string) => {
    track("User changes clinical information", {
      Field: field,
    })
  }

  return (
    <>
      <TherapyBookingFormWrapper
        summaryCardProps={props.summaryCardProps}
        onEditBooking={props.onEditBooking}
        header={
          <>
            <H2>Clinical information</H2>
            <P muted>
              We need this just in case we need to get in touch if we’re worried
              about you. Read more in our boundaries here.
            </P>
          </>
        }
      >
        <div className="flex flex-col gap-8">
          <TherapyBookingViewEditField
            registerFields={registerFieldRef}
            validationFieldNames={[
              "clinicalInfo.locationTown",
              "clinicalInfo.locationPostCode",
            ]}
            validationFieldValues={[
              form.watch("clinicalInfo.locationTown"),
              form.watch("clinicalInfo.locationPostCode"),
            ]}
            title="Approximate location"
            description="Used rarely, but in an emergency it’s important for Spill to know which regional services to contact."
            readOnlyValue={`${form.watch("clinicalInfo.locationTown") ?? ""} ${form.watch("clinicalInfo.locationPostCode")?.toUpperCase() ?? ""}`}
          >
            <div className="flex gap-4">
              <Form.Field
                control={form.control}
                name="clinicalInfo.locationTown"
                render={({ field }) => (
                  <Form.Item className="w-full">
                    <Form.Label htmlFor={field.name}>
                      <P weight="medium">City/Town</P>
                    </Form.Label>
                    <Form.Control>
                      <Input
                        {...field}
                        onBlur={async () => {
                          await handleBlur("City/Town")
                        }}
                        type="text"
                        placeholder="e.g. London"
                      />
                    </Form.Control>
                    <Form.Message />
                  </Form.Item>
                )}
              />
              <Form.Field
                control={form.control}
                name="clinicalInfo.locationPostCode"
                render={({ field }) => (
                  <Form.Item className="w-full">
                    <Form.Label htmlFor={field.name}>
                      <P weight="medium">Postcode</P>
                    </Form.Label>
                    <Form.Control>
                      <Input
                        {...field}
                        onBlur={async () => {
                          await handleBlur("Postcode")
                        }}
                        type="text"
                        placeholder="e.g. E1 6AN"
                      />
                    </Form.Control>
                    <Form.Message />
                  </Form.Item>
                )}
              />
            </div>
          </TherapyBookingViewEditField>
          <Separator />
          <TherapyBookingViewEditField
            registerFields={registerFieldRef}
            validationFieldNames={[
              "clinicalInfo.emergencyContactName",
              "clinicalInfo.emergencyCountryCode",
              "clinicalInfo.emergencyContactPhone",
            ]}
            validationFieldValues={[
              form.watch("clinicalInfo.emergencyContactName"),
              form.watch("clinicalInfo.emergencyCountryCode"),
              form.watch("clinicalInfo.emergencyContactPhone"),
            ]}
            title="Emergency contact"
            description="In an emergency it’s important for Spill to have someone you have selected for us to contact."
            readOnlyValue={formattedEmergencyContact}
          >
            <div className="flex flex-col gap-4">
              <Form.Field
                control={form.control}
                name="clinicalInfo.emergencyContactName"
                render={({ field }) => (
                  <Form.Item className="w-full">
                    <Form.Label htmlFor={field.name}>
                      <P weight="medium">Emergency contact name</P>
                    </Form.Label>
                    <Form.Control>
                      <Input
                        {...field}
                        onBlur={async () => {
                          await handleBlur("Emergency contact name")
                        }}
                        placeholder="e.g. Jane Doe"
                      />
                    </Form.Control>
                    <Form.Message />
                  </Form.Item>
                )}
              />
              <div className="space-y-2">
                <Label htmlFor={"userInfo.phone"}>
                  <P weight="medium" size="default">
                    Emergency contact number
                  </P>
                </Label>

                <div className="flex gap-4">
                  <Form.Field
                    control={form.control}
                    name="clinicalInfo.emergencyCountryCode"
                    render={({ field }) => (
                      <Form.Item>
                        <Form.Control>
                          <Select.Root
                            {...field}
                            onValueChange={async v => {
                              field.onChange(v)
                              await handleBlur(
                                "Emergency contact phone country code"
                              )
                            }}
                          >
                            <Select.Trigger>
                              <Select.Value placeholder={`Select Ext.`} />
                            </Select.Trigger>
                            <Select.Content>
                              {phoneCountryCodes.map(option => (
                                <Select.Item
                                  key={option.countryCode}
                                  value={option.countryCode}
                                >
                                  +{option.phonePrefix}
                                </Select.Item>
                              ))}
                            </Select.Content>
                          </Select.Root>
                        </Form.Control>
                      </Form.Item>
                    )}
                  />
                  <Form.Field
                    control={form.control}
                    name="clinicalInfo.emergencyContactPhone"
                    render={({ field }) => (
                      <Form.Item className="w-full">
                        <Form.Control>
                          <Input
                            {...field}
                            onBlur={async () => {
                              await handleBlur("Emergency contact phone")
                            }}
                            type="tel"
                            placeholder="XXXX XXXXXXX"
                          />
                        </Form.Control>
                        <Form.Message />
                      </Form.Item>
                    )}
                  />
                </div>
              </div>
            </div>
          </TherapyBookingViewEditField>
          <Separator />
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <H3>Boundaries and cancellation</H3>
              <P muted>Make sure you’ve read and agree to the terms. </P>
            </div>
            <Form.Field
              control={form.control}
              name="clinicalInfo.boundaries"
              render={({ field }) => (
                <Form.Item className="w-full">
                  <Form.Control>
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onClick={async () => {
                        form.setValue("clinicalInfo.boundaries", !field.value)
                        await checkValid()
                      }}
                      value={field.value.toString()}
                      label={{
                        children: (
                          <P weight="bold">
                            I’ve read and agreed to the{" "}
                            <a
                              className="underline text-spill-blue-800"
                              title="Link to Spill's boundaries"
                              target="_blank"
                              href="https://spill.notion.site/Boundaries-b612e43619874fd2bbd7b9ab1764f14c"
                              rel="noreferrer"
                            >
                              boundaries
                            </a>
                          </P>
                        ),
                      }}
                    />
                  </Form.Control>
                  <Form.Message />
                </Form.Item>
              )}
            />
            <Form.Field
              control={form.control}
              name="clinicalInfo.notice"
              render={({ field }) => (
                <Form.Item className="w-full">
                  <Form.Control>
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onClick={async () => {
                        form.setValue("clinicalInfo.notice", !field.value)
                        await checkValid()
                      }}
                      value={field.value.toString()}
                      label={{
                        children: (
                          <P weight="bold">
                            I agree to give at least 24 hours notice if I can’t
                            attend
                          </P>
                        ),
                      }}
                    />
                  </Form.Control>
                  <Form.Message />
                </Form.Item>
              )}
            />
          </div>
        </div>
      </TherapyBookingFormWrapper>

      <Button
        variant="primary"
        type="button"
        onClick={validationFormSectionAndContinue}
      >
        Save and continue
      </Button>
    </>
  )
}
