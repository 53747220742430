import { Button, Form, H2, P, Separator } from "@spillchat/puddles"
import { FunctionComponent, useEffect, useState } from "react"

import { BookableAppointmentType } from "types/graphql"
import { useAnalytics } from "common/context/analyticsContext"

import { useBookingFormValidation } from "../hooks/useBookingFormValidation"
import { TherapyBookingFormWrapper } from "../components/therapy-booking-form-wrapper"
import { TherapyBookingSummaryCardProps } from "../components/therapy-booking-summary-card"
import { SupportAreas } from "../components/preSession-form-questions/support-areas"
import { CopingRating } from "../components/preSession-form-questions/coping-rating"
import { CurrentEmotions } from "../components/preSession-form-questions/current-emotions"
import { SessionGoals } from "../components/preSession-form-questions/session-goals"
import { TherapyBookingFormSchema } from "../hooks/useTherapyBookingForm"
import { ParentLeave } from "../components/preSession-form-questions/parenthood-parental-leave"
import { WhatBroughtYouToSpill } from "../components/preSession-form-questions/whatBroughtYouToSpill"
import { BirthComplications } from "../components/preSession-form-questions/parenthood-birth-complications"
import { RecentLoss } from "../components/preSession-form-questions/bereavement-recent-loss"
import {
  isBereavementAppointmentType,
  isParenthoodAppointmentType,
} from "../helpers/appointmentTypeHelper"
import { RecentLossAffectedHow } from "../components/preSession-form-questions/bereavement-affected-how"
import { PreviousBereavementCounselling } from "../components/preSession-form-questions/bereavement-had-counselling"
import { TherapyBookingValidationContainer } from "../components/therapy-booking-validation-container"

import { AnonymityInSpill } from "./therapy-booking-appointment-booking-tab"

interface TherapyBookingPreSessionTabProps {
  onNext: () => void
  onEditBooking: () => void
  summaryCardProps: TherapyBookingSummaryCardProps
  isSubmitting: boolean
}

export const TherapyBookingPreSessionTab: FunctionComponent<
  TherapyBookingPreSessionTabProps
> = (props: TherapyBookingPreSessionTabProps) => {
  const [formType, setFormType] = useState<
    "PARENTHOOD" | "BEREAVEMENT" | "STANDARD"
  >("STANDARD")
  const form = Form.useFormContext<TherapyBookingFormSchema>()
  const { track } = useAnalytics()

  useEffect(() => {
    track("Booking tab changed to Pre-Session Questions")
  }, [])

  useEffect(() => {
    const appointmentTypeRaw = form.watch("appointmentType")
    const appointmentType = appointmentTypeRaw as BookableAppointmentType
    if (isParenthoodAppointmentType(appointmentType)) {
      setFormType("PARENTHOOD")
    } else if (isBereavementAppointmentType(appointmentType)) {
      setFormType("BEREAVEMENT")
    } else {
      setFormType("STANDARD")
    }
  }, [form.watch("appointmentType")])

  const { validationFormSectionAndContinue, checkValid, registerFieldRef } =
    useBookingFormValidation({
      formSection: "preSession",
      onNext: async () => {
        //submit form

        await form.handleSubmit(props.onNext)()
      },
    })

  const handleBlur = async (field: string) => {
    await checkValid()
    logChange(field)
  }

  const logChange = (field: string) => {
    track("User enters baseline question", {
      Field: field,
    })
  }

  return (
    <>
      <TherapyBookingFormWrapper
        summaryCardProps={props.summaryCardProps}
        onEditBooking={props.onEditBooking}
        header={
          <>
            <H2>Information for your therapist</H2>
            <P muted>
              We’ll ask you a few questions about what you’re experiencing.
              Nobody apart from your therapist will see this.
            </P>
          </>
        }
      >
        <div className="flex flex-col gap-8">
          {formType === "PARENTHOOD" && (
            <>
              <TherapyBookingValidationContainer
                fieldNames={["preSession.parentalLeave"]}
                registerFields={registerFieldRef}
              >
                <ParentLeave
                  checkValid={async () => await handleBlur("On parental leave")}
                />
              </TherapyBookingValidationContainer>
              <Separator />
              <TherapyBookingValidationContainer
                fieldNames={["preSession.copingRating"]}
                registerFields={registerFieldRef}
              >
                <CopingRating
                  checkValid={async () => await handleBlur("Coping rating")}
                />
              </TherapyBookingValidationContainer>
              <Separator />
              <TherapyBookingValidationContainer
                fieldNames={["preSession.currentEmotions"]}
                registerFields={registerFieldRef}
              >
                <CurrentEmotions
                  checkValid={async () =>
                    await handleBlur("Feelings/Feelings Frequency")
                  }
                />
              </TherapyBookingValidationContainer>
              <Separator />
              <TherapyBookingValidationContainer
                fieldNames={["preSession.birthComplications"]}
                registerFields={registerFieldRef}
              >
                <BirthComplications
                  checkValid={async () =>
                    await handleBlur("Birth complications")
                  }
                />
              </TherapyBookingValidationContainer>
            </>
          )}
          {formType === "BEREAVEMENT" && (
            <>
              <TherapyBookingValidationContainer
                fieldNames={["preSession.recentLoss"]}
                registerFields={registerFieldRef}
              >
                <RecentLoss
                  checkValid={async () => await handleBlur("Recent loss")}
                />
              </TherapyBookingValidationContainer>
              <Separator />
              <TherapyBookingValidationContainer
                fieldNames={["preSession.recentLossAffectedHow"]}
                registerFields={registerFieldRef}
              >
                <RecentLossAffectedHow
                  checkValid={async () =>
                    await handleBlur("Recent loss affected how")
                  }
                />
              </TherapyBookingValidationContainer>
              <Separator />
              <TherapyBookingValidationContainer
                fieldNames={["preSession.previousBereavementCounselling"]}
                registerFields={registerFieldRef}
              >
                <PreviousBereavementCounselling
                  checkValid={async () =>
                    await handleBlur("Previous bereavement counselling")
                  }
                />
              </TherapyBookingValidationContainer>
              <Separator />
              <TherapyBookingValidationContainer
                fieldNames={["preSession.copingRating"]}
                registerFields={registerFieldRef}
              >
                <CopingRating
                  checkValid={async () => await handleBlur("Coping rating")}
                />
              </TherapyBookingValidationContainer>
              <Separator />
              <TherapyBookingValidationContainer
                fieldNames={["preSession.currentEmotions"]}
                registerFields={registerFieldRef}
              >
                <CurrentEmotions
                  checkValid={async () =>
                    await handleBlur("Feelings/Feelings Frequency")
                  }
                />
              </TherapyBookingValidationContainer>
            </>
          )}
          {formType === "STANDARD" && (
            <>
              <TherapyBookingValidationContainer
                fieldNames={["preSession.notes"]}
                registerFields={registerFieldRef}
              >
                <WhatBroughtYouToSpill
                  checkValid={async () =>
                    await handleBlur("Reasons for booking")
                  }
                />
              </TherapyBookingValidationContainer>
              <Separator />
              <TherapyBookingValidationContainer
                fieldNames={["preSession.supportAreas"]}
                registerFields={registerFieldRef}
              >
                <SupportAreas
                  checkValid={async () => await handleBlur("Support areas")}
                />
              </TherapyBookingValidationContainer>
              <Separator />
              <TherapyBookingValidationContainer
                fieldNames={["preSession.copingRating"]}
                registerFields={registerFieldRef}
              >
                <CopingRating
                  checkValid={async () => await handleBlur("Coping rating")}
                />
              </TherapyBookingValidationContainer>
              <Separator />
              <TherapyBookingValidationContainer
                fieldNames={["preSession.currentEmotions"]}
                registerFields={registerFieldRef}
              >
                <CurrentEmotions
                  checkValid={async () =>
                    await handleBlur("Feelings/Feelings Frequency")
                  }
                />
              </TherapyBookingValidationContainer>
              <Separator />
              <TherapyBookingValidationContainer
                fieldNames={["preSession.sessionGoals"]}
                registerFields={registerFieldRef}
              >
                <SessionGoals
                  checkValid={async () => await handleBlur("Goals")}
                />
              </TherapyBookingValidationContainer>
            </>
          )}
        </div>
      </TherapyBookingFormWrapper>

      <Button
        variant="primary"
        type="button"
        onClick={validationFormSectionAndContinue}
        disabled={props.isSubmitting}
      >
        Book session
      </Button>
      <AnonymityInSpill />
    </>
  )
}
