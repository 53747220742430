import { Alert, Button, P } from "@spillchat/puddles"
import { ReactNode } from "react"
import { Link } from "react-router-dom"

export default function AdminTherapyProcessingAlert(): ReactNode {
  return (
    <Alert variant="warning" title="Your package isn't live yet">
      <>
        <P>
          It will take 2&mdash;3 working days to process while we find the right
          specialists. Until then, nobody will be able to see or use this
          package.
        </P>
        <P>
          If this support is urgent,{" "}
          <Button asChild variant="tertiary">
            <Link to="/admin/help?topic=therapy_access">get in touch</Link>
          </Button>
          .
        </P>
      </>
    </Alert>
  )
}
