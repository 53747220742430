import { zodResolver } from "@hookform/resolvers/zod"
import { Form } from "@spillchat/puddles"
import { z } from "zod"

import {
  extractPhoneNumberAndCountryCode,
  getCountryCodeFromTimezone,
} from "common/helpers/phone-country-codes"
import {
  GetUserAppointmentInfoQuery,
  BookableAppointmentType,
  Gender,
} from "types/graphql"
import { useApp } from "common/context/appContext"
import { useSearchParams } from "common/helpers/useSearchParams"

import { therapyBookingFormSchema } from "../therapy-booking.schema"
import {
  isBereavementAppointmentType,
  isParenthoodAppointmentType,
} from "../helpers/appointmentTypeHelper"

interface useTherapyBookingFormProps {
  userData: GetUserAppointmentInfoQuery
}

const getPhoneNumbersOrUndefined = (
  phoneNumber?: string | null,
  appTimeZone?: string
) => {
  if (phoneNumber !== undefined && phoneNumber !== null) {
    return extractPhoneNumberAndCountryCode(phoneNumber, appTimeZone)
  }
  return undefined
}

const supportAnswerOptions = [
  "I’ve had a major event in my life",
  "I’m struggling with my mental health",
  "I’m struggling in a relationship",
  "I’m struggling wth an issue at work",
  "Other",
]

const sessionGoalsAnswerOptions = [
  "A space to be heard",
  "Someone to challenge my thoughts",
  "Clarity and perspective on my situation",
  "Set and work towards explicit goals",
  "Other",
]

export type TherapyBookingFormSchema = z.input<typeof therapyBookingFormSchema>

export function useTherapyBookingForm(
  props: useTherapyBookingFormProps
): ReturnType<typeof Form.useForm<TherapyBookingFormSchema>> {
  const { appTimeZone } = useApp()
  const [searchParams] = useSearchParams()
  const appointmentTypeSearchParam = searchParams.get("appointmentType")

  const userData = props.userData
  const dateSplit = userData?.user?.therapyProfile?.dateOfBirth?.split("-")

  const phoneWithCountryCode = getPhoneNumbersOrUndefined(
    userData?.user?.therapyProfile?.phoneNumber,
    appTimeZone
  )
  const emergencyPhoneWithCountryCode = getPhoneNumbersOrUndefined(
    userData?.user?.therapyProfile?.emergencyContactNumber,
    appTimeZone
  )

  const appointmentType = appointmentTypeSearchParam as BookableAppointmentType
  const parenthoodType = isParenthoodAppointmentType(appointmentType)
  const bereavementType = isBereavementAppointmentType(appointmentType)
  const standardType = !parenthoodType && !bereavementType
  const counsellorIds = searchParams.getAll("counsellorId")

  // Location stored as a single string.
  // The data might look like "London, SE12 3DA" or "London, UK, SW1A 1AA" or just a postcode "SE12 3DA"
  const existingLocation =
    userData?.user?.therapyProfile?.approximateLocation?.split(", ")
  const existingLocationPostCode = existingLocation?.pop()
  const existingLocationCity = existingLocation?.join(", ")

  return Form.useForm<TherapyBookingFormSchema>({
    resolver: zodResolver(therapyBookingFormSchema),
    defaultValues: {
      appointmentType: appointmentType,
      timeZone: appTimeZone,
      filter: {
        counsellorIds: counsellorIds.length > 0 ? counsellorIds : undefined,
        gender: (searchParams.get("gender") as Gender) ?? undefined,
        language: searchParams.get("language") ?? "en",
        previousCounsellorId:
          searchParams.get("previousCounsellorId") ?? undefined,
        specialism: searchParams.get("specialism") ?? undefined,
        lifeEvent: searchParams.get("lifeEvent") ?? undefined,
        pmiOnly: appointmentType === BookableAppointmentType.PMI_SESSION,
      },
      userInfo: {
        dateOfBirth: {
          dayOfBirth: dateSplit?.[2],
          monthOfBirth: dateSplit?.[1],
          yearOfBirth: dateSplit?.[0],
        },
        email: userData?.user?.therapyProfile?.email ?? "",
        firstName:
          userData?.user?.therapyProfile?.firstName ??
          userData?.user?.firstName ??
          "",
        lastName:
          userData?.user?.therapyProfile?.lastName ??
          userData?.user?.lastName ??
          "",
        phone: phoneWithCountryCode?.phoneNumber ?? "",
        phoneCountryCode:
          phoneWithCountryCode?.countryInfo?.countryCode ??
          getCountryCodeFromTimezone(appTimeZone),
        pronouns: userData?.user?.therapyProfile?.pronouns ?? "",
      },
      clinicalInfo: {
        emergencyContactName:
          userData?.user?.therapyProfile?.emergencyContactName ?? "",
        emergencyContactPhone: emergencyPhoneWithCountryCode?.phoneNumber ?? "",
        emergencyCountryCode:
          emergencyPhoneWithCountryCode?.countryInfo?.countryCode ??
          getCountryCodeFromTimezone(appTimeZone),
        locationPostCode: existingLocationPostCode ?? "",
        locationTown: existingLocationCity ?? "",
        notice: false,
        boundaries: false,
      },
      preSession: {
        notes: {
          usedByAppointmentType: standardType,
          value: standardType ? "" : "N/A",
        },
        parentalLeave: {
          usedByAppointmentType: parenthoodType,
          value: parenthoodType ? "" : "N/A",
        },
        recentLoss: {
          usedByAppointmentType: bereavementType,
          value: bereavementType ? "" : "N/A",
        },
        recentLossAffectedHow: {
          usedByAppointmentType: bereavementType,
          value: bereavementType ? "" : "N/A",
        },
        previousBereavementCounselling: {
          usedByAppointmentType: bereavementType,
          value: bereavementType ? "" : "N/A",
        },
        supportAreas: {
          usedByAppointmentType: standardType,
          options: supportAnswerOptions.map((option, i) => ({
            key: i,
            label: option,
            value: option === "Other" ? "" : option,
            selected: false,
          })),
        },
        copingRating: undefined,
        currentEmotions: [],
        birthComplications: {
          usedByAppointmentType: parenthoodType,
          value: parenthoodType ? undefined : "N/A",
        },
        sessionGoals: {
          usedByAppointmentType: standardType,
          options: sessionGoalsAnswerOptions.map((option, i) => ({
            key: i,
            label: option,
            value: option === "Other" ? "" : option,
            selected: false,
          })),
        },
      },
    },
  })
}
