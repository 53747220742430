import { Button, Calendar, H2, P, Form, H3 } from "@spillchat/puddles"
import { FunctionComponent, useEffect, useMemo, useState } from "react"
import { useLazyQuery } from "@apollo/client"
import { formatISO, max, endOfHour, endOfDay, addWeeks } from "date-fns"
import { EyeSlashIcon } from "@heroicons/react/24/outline"
import { toZonedTime } from "date-fns-tz"
import { TZDate } from "@date-fns/tz"

import {
  BookableAppointmentType,
  GetAvailableAppointmentSlotsQuery,
  GetAvailableAppointmentSlotsQueryVariables,
  GetNextAppointmentSlotWithAvailabilityQuery,
  GetNextAppointmentSlotWithAvailabilityQueryVariables,
} from "types/graphql"
import { useAnalytics } from "common/context/analyticsContext"

import { transformFiltersForGQL } from "../helpers/filtersHelper"
import { therapyBookingQueries } from "../therapy-booking.queries"
import { TherapyBookingFilters } from "../components/therapy-booking-filters"
import { TherapyBookingFormSchema } from "../hooks/useTherapyBookingForm"
import { TimeZoneInput } from "../components/therapy-booking-timezone"

interface TherapyBookingAppointmentBookingTabProps {
  appointmentType: BookableAppointmentType
  onNext: () => void
  allowedToBookFromCalendar: boolean
  onSubmit: () => void
  firstDay: Date
  onFirstDayChange: (date: Date) => void
  onTimezoneChange?: () => void
  parentLoaded: boolean
}

export const TherapyBookingAppointmentBookingTab: FunctionComponent<
  TherapyBookingAppointmentBookingTabProps
> = (props: TherapyBookingAppointmentBookingTabProps) => {
  const [cacheState, setCacheState] = useState(0)
  const { track } = useAnalytics()

  useEffect(() => {
    if (!props.parentLoaded) {
      return
    }
    track("Booking tab changed to Time Selection")
  }, [])

  const [getAvailableAppointmentSlots] = useLazyQuery<
    GetAvailableAppointmentSlotsQuery,
    GetAvailableAppointmentSlotsQueryVariables
  >(therapyBookingQueries.getAvailableAppointmentSlots, {
    fetchPolicy: "network-only",
  })

  const [getNextDayWithAvailability] = useLazyQuery<
    GetNextAppointmentSlotWithAvailabilityQuery,
    GetNextAppointmentSlotWithAvailabilityQueryVariables
  >(therapyBookingQueries.getNextDayWithAvailability, {
    fetchPolicy: "network-only",
  })

  const form = Form.useFormContext<TherapyBookingFormSchema>()

  const hasPickedADate = useMemo(() => {
    const startTime = form.getValues("startTime")
    return startTime !== "" && startTime !== undefined
  }, [form.getValues("startTime")])

  const handleFetchAvailableTimes = async (date: Date) => {
    const timeZone = form.watch("timeZone")

    //Dates in are based on the user's timezone
    const startOfDayInTZ = new TZDate(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      timeZone
    )
    const endOfDayInTZ = endOfDay(
      new TZDate(date.getFullYear(), date.getMonth(), date.getDate(), timeZone)
    )

    //Convert to UTC for the server
    const startOfDayUTC = new Date(startOfDayInTZ.toUTCString())
    const endOfDayUTC = new Date(endOfDayInTZ.toUTCString())

    const { data } = await getAvailableAppointmentSlots({
      variables: {
        appointmentType: props.appointmentType,
        // If the request start date is in the past, request from the current hour
        startDate: formatISO(max([startOfDayUTC, endOfHour(new Date())])),
        endDate: formatISO(endOfDayUTC),
        filter: transformFiltersForGQL(
          form.watch("filter"),
          props.appointmentType
        ),
      },
    })

    //Results are in UTC time
    const availableSlots =
      data?.availableAppointmentSlots.map(slot => slot.startTime) ?? []

    //Convert them back to the user's timezone
    const convertedSlots = availableSlots.map(slot => {
      const slotDate = new Date(slot)
      return toZonedTime(slotDate, timeZone).toISOString()
    })

    return convertedSlots
  }

  const handleGetNextAvailableTime = async () => {
    const { data } = await getNextDayWithAvailability({
      variables: {
        appointmentType: props.appointmentType,
        filter: transformFiltersForGQL(
          form.watch("filter"),
          props.appointmentType
        ),
      },
    })

    if (
      data?.nextDayWithAvailability === null ||
      data?.nextDayWithAvailability === undefined
    ) {
      return undefined
    }

    return new Date(data.nextDayWithAvailability)
  }

  const handleTimeZoneChange = (timeZone: string) => {
    track("User changed timezone", {
      oldTimeZone: form.watch("timeZone"),
      timeZone,
    })

    setCacheState(prev => prev + 1)

    form.setValue("timeZone", timeZone)

    if (props.onTimezoneChange !== undefined) {
      props.onTimezoneChange()
    }
  }

  const getSelectedTime = () => {
    return form.watch("startTime")
  }

  const handleNext = () => {
    track("User selects time of session", {
      "Start time": form.watch("startTime"),
    })
    props.onNext()
  }

  return (
    <>
      <div className="flex flex-col gap-12">
        <div className="flex flex-col gap-2">
          <H2>Find an appointment slot</H2>
          <P muted>Choose from the available appointment slots</P>
          <TimeZoneInput onTimeZoneChange={handleTimeZoneChange} />
        </div>
        <div className="grid grid-cols-12 gap-y-8">
          <div className="col-span-12 md:col-span-4 md:order-last bg-spill-mono-white z-50 p-0 md:px-8">
            <TherapyBookingFilters
              appointmentType={props.appointmentType}
              onFiltersChange={() => {
                setCacheState(prev => prev + 1)
              }}
            />
          </div>
          <div className="col-span-12 md:col-span-8 flex flex-col gap-8">
            <Calendar
              maxDate={endOfDay(addWeeks(new Date(), 12))}
              selectedTime={getSelectedTime()}
              cacheState={cacheState}
              startDate={props.firstDay}
              onStartDateChange={startDate => {
                props.onFirstDayChange(startDate)
              }}
              onTimeSelect={timeSlot => {
                form.setValue("startTime", timeSlot ?? "", {
                  shouldDirty: true,
                })
              }}
              onFetchAvailableTimes={handleFetchAvailableTimes}
              onGetNextAvailableTime={handleGetNextAvailableTime}
              noDataAvailableNode={
                <div className="space-y-4 text-center">
                  <H3>
                    We don’t currently have any counsellors that match your
                    criteria
                  </H3>
                  <P>Try removing some of the filters you’ve selected</P>
                </div>
              }
            />
            {!props.allowedToBookFromCalendar && (
              <Button
                variant="primary"
                type="button"
                onClick={handleNext}
                disabled={!hasPickedADate}
              >
                Next
              </Button>
            )}
            {props.allowedToBookFromCalendar && (
              <div className="flex gap-4">
                <Button
                  variant="secondary"
                  type="button"
                  onClick={handleNext}
                  disabled={!hasPickedADate}
                >
                  Edit details
                </Button>
                <Button
                  variant="primary"
                  type="button"
                  onClick={props.onSubmit}
                  disabled={!hasPickedADate}
                >
                  Book appointment
                </Button>
              </div>
            )}
            <AnonymityInSpill />
          </div>
        </div>
      </div>
    </>
  )
}

export const AnonymityInSpill: FunctionComponent = () => {
  return (
    <div className="flex items-center gap-4 self-stretch">
      <div className="h-6 w-6">
        <EyeSlashIcon className="text-spill-blue-800" />
      </div>
      <P muted>
        We take privacy seriously. See what your company will see when you book
        therapy.
        <br />
        <a
          href="https://spill.notion.site/Anonymity-on-Spill-15e49f92fc6b80dfba75dc942ced01bf"
          target="_blank"
          rel="noreferrer"
          className="underline decoration-solid font-semibold text-spill-mono-black"
        >
          Anonymity on Spill.
        </a>
      </P>
    </div>
  )
}
