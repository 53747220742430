import { FunctionComponent, useState } from "react"
import { useMutation, useQuery } from "@apollo/client"
import { H1, P, H2, Alert, Button } from "@spillchat/puddles"
import { Helmet } from "react-helmet-async"
import { Link, Navigate } from "react-router-dom"
import {
  CheckIcon,
  HeartIcon,
  CalendarDaysIcon,
  LightBulbIcon,
  LifebuoyIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline"
import { toast } from "sonner"

import imageParenthood from "common/assets/images/product/specialised-support/therapy-parenthood.png"
import imageBereavement from "common/assets/images/product/specialised-support/therapy-bereavement.png"
import imageAdhd from "common/assets/images/product/specialised-support/therapy-adhd.png"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import {
  PackageState,
  PackageType,
  TherapyExtensionStatus,
  TherapySpecialisedSupportIndexCancelMutation,
  TherapySpecialisedSupportIndexCancelMutationVariables,
  TherapySpecialisedSupportIndexQuery,
  TherapySpecialisedSupportIndexQueryVariables,
} from "types/graphql"
import { SpecialisedSupportItem } from "features/specialised-support/components/SpecialisedSupportItem"
import { Dialog } from "common/components/Dialog"

import { queries } from "./therapy-specialised-support.queries"
import { mutations } from "./therapy-specialised-support.mutations"

export const TherapySpecialisedSupportPage: FunctionComponent = () => {
  const [cancelRequestModal, setCancelRequestModal] = useState<string | null>(
    null
  )

  const { data, called, loading } = useQuery<
    TherapySpecialisedSupportIndexQuery,
    TherapySpecialisedSupportIndexQueryVariables
  >(queries.getPackages)

  const [cancelRequest] = useMutation<
    TherapySpecialisedSupportIndexCancelMutation,
    TherapySpecialisedSupportIndexCancelMutationVariables
  >(mutations.cancelRequest, {
    refetchQueries: ["TherapySpecialisedSupportIndex"],
    onCompleted() {
      setCancelRequestModal(null)
      toast("Request has been cancelled")
    },
  })

  if (!called || loading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoadingSpinner sizeInPixels={32} />
      </div>
    )
  }

  const packages = data?.user?.visibleUnclaimedTherapyPackages
  const therapyPackages = data?.user?.therapyPackages

  const bereavementPackage = therapyPackages?.find(packageDetails => {
    return packageDetails.identifier === PackageType.BEREAVEMENT.toString()
  })

  const parenthoodPackage = therapyPackages?.find(packageDetails => {
    return packageDetails.identifier === PackageType.PARENTHOOD.toString()
  })

  const hasBereavement = bereavementPackage !== undefined
  const hasParenthood = parenthoodPackage !== undefined
  const hasAdhdSupport =
    data?.user?.company?.adhdCompanyPackageSetting?.state ===
    PackageState.ACTIVE

  if (!packages) {
    return <Navigate to="/" />
  }

  const packagesAvailable = [
    hasBereavement,
    hasParenthood,
    hasAdhdSupport,
    packages != null && packages.length > 0,
    therapyPackages != null && therapyPackages.length > 0,
  ].some(packages => packages === true)

  return (
    <>
      <Helmet title="Specialised support | Spill" />
      <section className="flex flex-col gap-12">
        <div className="flex flex-col gap-4 max-w-screen-sm">
          <H1>Specialist support</H1>
          <H2>How it works</H2>
          <P muted>
            Your company may have set up additional therapy policies that are
            designed to support you through particular life events or
            situations. You can see which specialist support packages your
            company has authorised on this page.
          </P>
          <P muted>
            The options below are policies already set in place by your company.
          </P>
        </div>
        {!packagesAvailable ? (
          <div className="max-w-screen-sm">
            <Alert title="There are currently no packages available" />
          </div>
        ) : (
          <div className="grid grid-cols-1 xl:grid-cols-2 gap-8">
            {hasBereavement && (
              <SpecialisedSupportItem
                title="Bereavement support"
                subtitle="For grieving employees who might be on leave, or struggling on the anniversary of losing a loved one."
                list={[
                  {
                    Icon: LifebuoyIcon,
                    text: "Sessions with a bereavement specialist",
                  },
                  {
                    Icon: HeartIcon,
                    text: "6 sessions on top of your regular allowance",
                  },
                  {
                    Icon: CalendarDaysIcon,
                    text: "Support for 2 years",
                  },
                ]}
                image={imageBereavement}
              >
                <Button asChild>
                  <Link to="/therapy/specialised-support/bereavement">
                    Get started
                  </Link>
                </Button>
              </SpecialisedSupportItem>
            )}
            {hasParenthood && (
              <SpecialisedSupportItem
                title="Parenthood support"
                subtitle="For new parents going through the joys of welcoming a child and the challenges around returning to work."
                list={[
                  {
                    Icon: LifebuoyIcon,
                    text: "Sessions with a parenthood specialist",
                  },
                  {
                    Icon: HeartIcon,
                    text: "8 sessions on top of your regular allowance",
                  },
                  {
                    Icon: CalendarDaysIcon,
                    text: "Support for 2 years",
                  },
                ]}
                image={imageParenthood}
              >
                <Button asChild>
                  <Link to="/therapy/specialised-support/parenthood">
                    Get started
                  </Link>
                </Button>
              </SpecialisedSupportItem>
            )}
            {hasAdhdSupport && (
              <SpecialisedSupportItem
                title="ADHD support"
                subtitle="For employees that experience symptoms of Attention Deficit Hyperactivity Disorder, whether they've had a diagnosis or not."
                list={[
                  {
                    Icon: LightBulbIcon,
                    text: "Sessions with an ADHD specialist",
                  },
                  {
                    Icon: HeartIcon,
                    text: "Use your regular credit allowance",
                  },
                  {
                    Icon: CalendarDaysIcon,
                    text: "Renews every 6 months",
                  },
                ]}
                image={imageAdhd}
              >
                <Button asChild>
                  <Link to="/therapy/specialised-support/adhd">
                    Get started
                  </Link>
                </Button>
              </SpecialisedSupportItem>
            )}
            {packages
              .filter(pkg => pkg.packageType === PackageType.CUSTOM)
              .map((pkg, index) => {
                return (
                  <SpecialisedSupportItem
                    key={index}
                    title={pkg.name ?? "Custom package"}
                    subtitle={pkg.userFacingDescription ?? ""}
                    image={pkg.iconUrl ?? ""}
                    list={[
                      {
                        Icon: UserPlusIcon,
                        text: "Specialist tailored to your needs",
                      },
                      {
                        Icon: HeartIcon,
                        text: `${pkg.numberSessions} sessions on top of your regular allowance`,
                      },
                      {
                        Icon: CalendarDaysIcon,
                        text:
                          pkg.numberMonthsToUse != null
                            ? `Support for ${pkg.numberMonthsToUse} months`
                            : "Support indefinitely",
                      },
                    ]}
                  >
                    <div className="flex gap-2 items-center">
                      {pkg.mostRecentRequest != null &&
                      pkg?.mostRecentRequest.status ===
                        TherapyExtensionStatus.PENDING ? (
                        <>
                          <Button size="sm" variant="readonly" asChild>
                            <Link
                              to={"/therapy/specialised-support/" + pkg.id}
                              className="pl-2"
                            >
                              <div className="flex gap-1 items-center">
                                <CheckIcon width={16} />
                                <span>Package requested</span>
                              </div>
                            </Link>
                          </Button>
                          <Button
                            variant="destructive"
                            size="sm"
                            onClick={() => {
                              if (pkg.mostRecentRequest?.id != null) {
                                setCancelRequestModal(pkg.mostRecentRequest?.id)
                              }
                            }}
                          >
                            Cancel request
                          </Button>
                        </>
                      ) : (
                        <Button size="sm" variant="primary" asChild>
                          <Link to={"/therapy/specialised-support/" + pkg.id}>
                            {pkg.mostRecentRequest != null &&
                            pkg.mostRecentRequest.status ===
                              TherapyExtensionStatus.PENDING
                              ? "View package"
                              : "Get started"}
                          </Link>
                        </Button>
                      )}
                    </div>
                  </SpecialisedSupportItem>
                )
              })}
            {therapyPackages
              ?.filter(
                pkg =>
                  pkg.companyPackageSetting?.packageType === PackageType.CUSTOM
              )
              .map((pkg, index) => {
                return (
                  <SpecialisedSupportItem
                    key={index}
                    title={pkg.companyPackageSetting?.name ?? "Custom package"}
                    subtitle={
                      pkg.companyPackageSetting?.userFacingDescription ?? ""
                    }
                    image={pkg.companyPackageSetting?.iconUrl ?? ""}
                    list={[
                      {
                        Icon: HeartIcon,
                        text: `${pkg.numberSessionsGiven} sessions on top of your regular allowance`,
                      },
                    ]}
                  >
                    <Button asChild>
                      <Link
                        to={"/therapy/specialised-support/" + pkg.identifier}
                      >
                        View package
                      </Link>
                    </Button>
                  </SpecialisedSupportItem>
                )
              })}
          </div>
        )}
      </section>
      <Dialog
        canClose
        isOpen={cancelRequestModal !== null}
        onClose={() => setCancelRequestModal(null)}
        title="Cancel request?"
        buttons={[
          {
            key: "cancel",
            variant: "secondary",
            children: "Not now",
            onClick: () => setCancelRequestModal(null),
          },
          {
            key: "confirm",
            variant: "destructive",
            children: "Cancel request",
            onClick: async () => {
              if (cancelRequestModal !== null) {
                await cancelRequest({
                  variables: {
                    id: cancelRequestModal,
                  },
                })
              }
              setCancelRequestModal(null)
            },
          },
        ]}
      >
        <P>
          You will be able to request this again if you change your&nbsp;mind.
        </P>
      </Dialog>
    </>
  )
}
