/**
 * Displays the overall therapy usage as a progress bar.
 * Will display a variant if the company has reached its overall limit of sessions.
 */

import { H3, P, ProgressBar, Tooltip } from "@spillchat/puddles"
import { addMonths, format, isBefore, startOfMonth } from "date-fns"
import { FunctionComponent } from "react"
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline"

import { useAuth } from "common/context/authContext"
import { UserRole } from "types/graphql"

interface UsageAsPercentageProps {
  therapyUsageCap: number | null
  companyTherapyCap: number
  numberSessionsUsedInCapPeriod: number | null
  companyNumberSessionsUsedInBudgetYear: number
  companyYearlyTherapyBudget: number | null | undefined
  upcomingCapResetDate?: string | null
  companyName: string | null
  userCanRequestTherapy: boolean
  userTherapyCapActive: boolean
  hasInactiveSubscription: boolean
  hasLifeEventPackage: boolean
  companySessionPackLimit: number | null | undefined
  companySessionPackUsage: number | null | undefined
  companyAllowsCourses: boolean
  inActiveTrialPeriod: boolean | undefined
  trialStartDate?: string | null
  trialEndDate?: string | null
  revokedAt?: string | null
  capPeriod?: number
}

export const UsageAsPercentage: FunctionComponent<UsageAsPercentageProps> = ({
  therapyUsageCap,
  companyTherapyCap,
  companyNumberSessionsUsedInBudgetYear,
  companyYearlyTherapyBudget,
  numberSessionsUsedInCapPeriod,
  upcomingCapResetDate,
  companyName,
  userCanRequestTherapy,
  userTherapyCapActive,
  hasInactiveSubscription,
  hasLifeEventPackage,
  companySessionPackLimit,
  companySessionPackUsage,
  companyAllowsCourses,
  inActiveTrialPeriod,
  trialStartDate,
  trialEndDate,
  revokedAt,
  capPeriod,
}) => {
  const { user } = useAuth()

  const formattedDate =
    upcomingCapResetDate != null
      ? format(new Date(upcomingCapResetDate), "d MMMM yyyy")
      : ""
  const formattedSessionPackDate = format(
    startOfMonth(addMonths(new Date(), 1)),
    "d MMMM yyyy"
  )
  const formattedTrialEndDate =
    trialEndDate != null ? format(new Date(trialEndDate), "d MMMM yyyy") : ""
  const revokedAtDate = revokedAt != null ? new Date(revokedAt) : new Date()
  const revokedAt3MonthTimeout = addMonths(revokedAtDate, 3)
  const formattedRevokedAt3MonthTimeout = format(
    revokedAt3MonthTimeout,
    "d MMMM yyyy"
  )
  const unlimitedTherapyCap = therapyUsageCap == Number.MAX_SAFE_INTEGER

  // We can reliably display what we want to display if we don't have the data
  // So let's just reurn null if that's the case
  if (therapyUsageCap == null || numberSessionsUsedInCapPeriod == null) {
    return null
  }

  if (hasInactiveSubscription) {
    return (
      <div className="gap-y-2 flex flex-col row-start-2 col-span-1 md:col-span-4 xl:col-span-3">
        <div className="flex gap-2">
          <P weight="medium">Credits: company not active</P>
        </div>
      </div>
    )
  }

  // we need to account for the session that would be going over the limit, so we add 1 here
  if (
    companyYearlyTherapyBudget != null &&
    companyNumberSessionsUsedInBudgetYear + 1 > companyYearlyTherapyBudget
  ) {
    return (
      <div className="gap-y-2 flex flex-col row-start-2 col-span-1 md:col-span-4 xl:col-span-3">
        <div className="flex gap-2">
          <P weight="medium">Credits: company limit reached</P>
          <Tooltip.Provider>
            <Tooltip.Root>
              <Tooltip.Trigger>
                <QuestionMarkCircleIcon className="size-4" />
              </Tooltip.Trigger>
              <Tooltip.Content side="top" title="Company limit reached">
                Your company only has a limited number of sessions of therapy
                available for the year and that limit has been reached. You can
                pay privately or ask your Spill admin to increase the limit.
              </Tooltip.Content>
            </Tooltip.Root>
          </Tooltip.Provider>
        </div>
        <P muted>
          {upcomingCapResetDate != null && <>Renews: {formattedDate}</>}
        </P>
      </div>
    )
  } else if (
    companySessionPackLimit != null &&
    (companySessionPackUsage ?? 0) + 1 > companySessionPackLimit &&
    // we only show this if the user's company provided cap has not been reached (and the user has not been gifted extra sessions),
    // otherwise we display the caps message (in the next return block after this)
    numberSessionsUsedInCapPeriod < companyTherapyCap &&
    companyTherapyCap === therapyUsageCap
  ) {
    return (
      <div className="gap-y-2 flex flex-col row-start-2 col-span-1 md:col-span-4 xl:col-span-3">
        <div className="flex gap-2">
          <P weight="medium">Credits: company limit reached</P>
          <Tooltip.Provider>
            <Tooltip.Root>
              <Tooltip.Trigger>
                <QuestionMarkCircleIcon className="size-4" />
              </Tooltip.Trigger>
              <Tooltip.Content side="top" title="Company limit reached">
                Your company set aside a limited number of sessions of therapy
                available each month and that limit has been reached.
                {userCanRequestTherapy
                  ? " Request more sessions if you want a session before the reset date."
                  : ""}
              </Tooltip.Content>
            </Tooltip.Root>
          </Tooltip.Provider>
        </div>
        <P muted>
          {companySessionPackLimit != null && trialStartDate == null && (
            <>Renews: {formattedSessionPackDate}</>
          )}
        </P>
      </div>
    )
  } else if (revokedAt != null) {
    return (
      <div className="gap-y-2 flex flex-col row-start-2 col-span-1 md:col-span-4 xl:col-span-3">
        <div className="flex gap-2">
          <P weight="medium">You are no longer on a company account</P>
          <Tooltip.Provider>
            <Tooltip.Root>
              <Tooltip.Trigger>
                <QuestionMarkCircleIcon className="size-4" />
              </Tooltip.Trigger>
              <Tooltip.Content
                side="top"
                title="Your account has been deactivated"
              >
                You no longer have access to Spill through your company. If this
                is a mistake please contact the person in charge of Spill at
                your company.
              </Tooltip.Content>
            </Tooltip.Root>
          </Tooltip.Provider>
        </div>
        <P weight="regular">
          You can continue to book therapy sessions for up to 3 months and pay
          privately.
        </P>
        <P muted>
          <>
            Expire{isBefore(revokedAt3MonthTimeout, new Date()) ? "d" : "s"}:{" "}
            {formattedRevokedAt3MonthTimeout}
          </>
        </P>
      </div>
    )
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 items-start col-span-1 md:col-span-4 xl:col-span-3">
      <div className="col-span-1 md:col-span-2 max-w-xs">
        {unlimitedTherapyCap && (
          <>
            <H3>Unlimited credits</H3>
            <P weight="medium">
              You've used {numberSessionsUsedInCapPeriod} session credits
              provided by {companyName}
            </P>
          </>
        )}
        {!unlimitedTherapyCap && (
          <ProgressBar
            totalProgress={Math.max(
              therapyUsageCap,
              numberSessionsUsedInCapPeriod
            )}
            currentProgress={numberSessionsUsedInCapPeriod}
            title="Spill Credits"
            progressDetail={`credits used`}
            tooltip={
              user?.role !== UserRole.PLUS_ONE && !unlimitedTherapyCap
                ? {
                    title: `Your company pays for you to have ${Math.max(
                      therapyUsageCap,
                      numberSessionsUsedInCapPeriod
                    )} sessions of therapy`,
                    message: (
                      <>
                        {userCanRequestTherapy
                          ? "If your sessions run out you can book privately or request more from your company."
                          : "If your sessions run out you can book privately until your credits renew."}
                        {companyAllowsCourses ? (
                          <>
                            <br />
                            {
                              "Course consultations do not count towards your session credits."
                            }
                          </>
                        ) : (
                          ""
                        )}
                        {hasLifeEventPackage ? (
                          <>
                            <br />
                            {
                              "Specialised support is in addition to these session credits."
                            }
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    ),
                  }
                : undefined
            }
          />
        )}
      </div>
      <div className="gap-y-1 flex flex-col row-start-2 col-span-1 md:col-span-4 xl:col-span-3">
        {/*if the company has a therapy cap, we display the following message */}
        {trialStartDate === null && userTherapyCapActive === true && (
          <>
            {upcomingCapResetDate != null && !unlimitedTherapyCap && (
              <P weight="medium">Your Spill credits renew on {formattedDate}</P>
            )}
            <P muted>
              Credits provided by {companyName} reset every {capPeriod ?? 6}{" "}
              months. <br />
            </P>
          </>
        )}
        {/*if the company has a therapy cap, but is not we display the following message */}
        {trialStartDate === null && userTherapyCapActive === false && (
          <P muted>
            Credits provided by {companyName} expire
            {upcomingCapResetDate != null && <> after {formattedDate}</>}
          </P>
        )}
        {/* if the company is in a trial, we display slightly different copy if the trial is active or expired */}
        {trialStartDate !== null && trialEndDate != null && (
          <P muted>
            Credits expire{inActiveTrialPeriod == true ? "" : "d"}{" "}
            {<> after {formattedTrialEndDate}</>}
          </P>
        )}
      </div>
    </div>
  )
}
