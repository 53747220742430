import { FunctionComponent, useEffect } from "react"
import { gql, useQuery } from "@apollo/client"
import { H2, P } from "@spillchat/puddles"

import {
  AccountStatus,
  AccessListGetUserQuery,
  PlatformUserState,
  UserRole,
  PlatformType,
} from "types/graphql"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import { useApp } from "common/context/appContext"
import { ErrorPage } from "common/components/ErrorPage"

import { AccessTable, TableUser } from "./AccessTable"

const queries = {
  getUser: gql`
    query AccessListGetUser {
      user {
        id
        company {
          id
          subscriptionPlan
          subscriptionStatus
          platforms {
            platformType
          }
          users {
            accountStatus
            id
            fullName
            role
            platformUsers {
              email
              platform {
                id
                platformType
              }
            }
          }
          pendingUsers {
            id
            user {
              id
            }
            platform {
              id
              platformType
            }
            name
            email
            state
          }
        }
      }
    }
  `,
}

export const AccessList: FunctionComponent = () => {
  const { setPageBlob } = useApp()
  const { data, loading } = useQuery<AccessListGetUserQuery>(queries.getUser, {
    fetchPolicy: "cache-and-network",
  })

  useEffect(() => {
    setPageBlob("top")
  }, [])

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <LoadingSpinner sizeInPixels={64} />
      </div>
    )
  }

  if (data && data.user === undefined) {
    return (
      <ErrorPage>
        <span>An unexpected error occured please contact hi@spill.chat</span>
      </ErrorPage>
    )
  }

  const mapUsers = () => {
    const appUsers =
      data?.user?.company?.users.map(user => ({
        userId: user.id,
        identifier: "",
        platformType: user.platformUsers.at(0)?.platform?.platformType,
        email: user.platformUsers.length
          ? (user.platformUsers[0]?.email ?? "")
          : "",
        ...user,
      })) ?? []
    const pendingUsers =
      data?.user?.company?.pendingUsers.map(pu => ({
        platformUserId: pu.id,
        fullName: pu.name,
        identifier: "",
        platformType: pu.platform?.platformType,
        role: UserRole.STANDARD,
        email: pu.email ?? "",
        accountStatus:
          pu.state == PlatformUserState.DISABLED
            ? AccountStatus.NO_ACCESS
            : AccountStatus.ACTIVE,
      })) ?? []

    const mergedUsers = [...appUsers, ...pendingUsers]

    return mergedUsers.flatMap(user => {
      /**
       * Type TableUser does not include user.role = 'PLUS_ONE', as we
       * don't want to include those users in the access table.
       *
       * Had to user flatMap rather than a filter to typecast the return.
       */
      if (user.role === UserRole.PLUS_ONE) return []

      user.identifier = user.fullName || user.email
      return user as TableUser
    })
  }

  // copy the array first so we don't mutate the original
  const sortedUsers = [...mapUsers()].sort((userA, userB) => {
    const statusComparison =
      (userA.accountStatus === AccountStatus.ACTIVE ? -1 : 1) -
      (userB.accountStatus === AccountStatus.ACTIVE ? -1 : 1)
    const nameComparison = userA.fullName.localeCompare(userB.fullName)

    // Sort first by status then by name, all active users first
    return statusComparison || nameComparison
  })

  const tableUsers = sortedUsers

  const { subscriptionPlan } = data?.user?.company ?? {}

  const platformTypes = new Set(
    data?.user?.company?.platforms.map(platform => platform.platformType)
  )

  const hasSlack = platformTypes.has(PlatformType.SLACK)

  return (
    <div className="flex flex-col">
      <H2>User access</H2>
      <P muted className="mt-3 mb-8">
        Team members already using Spill
      </P>
      <AccessTable
        data={tableUsers}
        userId={data?.user?.id}
        subscriptionPlan={subscriptionPlan}
        hasSlack={hasSlack}
      />
    </div>
  )
}
